import React from 'react'

const Footer = () => {
    return (
        <footer class="bg-black section-padding footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="footer-box mb-md-80">
                            <div class="footer-heading">
                                <h4 class="text-custom-white no-margin">Cynatek Holdings LTD</h4>
                            </div>
                            <p class="text-light-grey-raised">We are a credit finance company offering salary loans to government employees. </p>

                            <div class="social-media">
                                {/* <ul class="custom social-media">

                                    <li><a href="#"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a href="#"><i class="fab fa-linkedin"></i></a>
                                    </li>

                                </ul> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-7">
                        <div class="footer-box mb-md-80">
                            <div class="footer-heading">
                                <h4 class="text-custom-white no-margin"> Services</h4>
                            </div>
                            <ul class="custom links ">
                                <li> <a href="/service" class="text-light-grey-raised">Salary Loan</a>
                                </li>



                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-6">
                        <div class="footer-box mb-md-80">
                            <div class="footer-heading">
                                <h4 class="text-custom-white no-margin"> Quick Links</h4>
                            </div>
                            <ul class="custom links">

                                <li> <a href="/service" class="text-light-grey-raised">About Us</a>
                                </li>

                                <li> <a href="/team" class="text-light-grey-raised">Team</a>
                                </li>
                                <li> <a href="/contact" class="text-light-grey-raised">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="footer-box mb-md-80">
                            <div class="footer-heading">
                                <h4 class="text-custom-white no-margin"> Our Social Media</h4>
                            </div>

                            <div class="social-media">
                                <ul class="custom social-media">

                                    <li><a href="#"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a href="#"><i class="fab fa-linkedin"></i></a>
                                    </li>


                                </ul>
                                <p class="text-custom-white">Address: Plot 4 Crown House Kampala Road, Floor 2 Room 3</p>


                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </footer >
    )
}

export default Footer
