import React from 'react'

const LoanContact = () => {
    return (
        <section class="book-appointment parallax section-padding" id="book-appointment">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="appointment-sec">
                            <div class="row no-gutters">
                                <div class="col-lg-6 ">
                                    <div class="padding-40 full-height bg-white">
                                        <div class="align-self-center">
                                            <div class="section-heading">
                                                <h3 class="text-dark-blue fw-600 ">Cynatek Holdings Limited</h3>

                                            </div>
                                            <p class="text-custom-black-raised">We Offer Affordable Lonas for Government Salary employees.</p>

                                            <div class="LOANLY-address">
                                                <p class="text-custom-black-raised ">Requirements</p>
                                                <ul class="text-custom-black-raised no-margin">
                                                    <li>National Identity Card</li>
                                                    <li>Worker’s Identity Card</li>
                                                    <li>1 Passport Photograph</li>
                                                    <li>Appointment / Confirmation Letter</li>
                                                    <li>Introduction Letter</li>
                                                    <li>Bank Statement</li>
                                                    <li>Pay slip</li>
                                                </ul>
                                            </div>
                                            <br /> <br />
                                            <h3 class="text-dark-blue fw-600 ">Why Us?</h3>
                                            <ul class="text-custom-black-raised no-margin">
                                                <li>Affordable Interest Rate</li>
                                                <li>No Security</li>
                                                <li>No Application Fees</li>
                                                <li>No Guarantee</li>

                                            </ul>
                                            <br /> <br />

                                            <button type="submit" class="btn-first btn-submit-fill"><i
                                                class="fas fa-phone mr-2 fs-16"></i><span
                                                    class="fs-16">+256 773 956212</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="right-side full-height bg-border">
                                        <div class="section-heading">
                                            <h3 class="text-white fw-600">You can book to apply for a loan</h3>
                                        </div>
                                        <form class="form-style-2 form-style-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend"><i
                                                            class="fas fa-user"></i></span>
                                                            <input type="text" name="#" class="form-control"
                                                                placeholder="Full Name" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend"><i
                                                            class="fas fa-envelope"></i></span>
                                                            <input type="email" name="#" class="form-control"
                                                                placeholder="Email Address" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend"><i
                                                            class="fas fa-phone-alt"></i></span>
                                                            <input type="text" name="#" class="form-control"
                                                                placeholder="Phone Number" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend">
                                                            <i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                                            <input type="home" name="#" class="form-control"
                                                                placeholder="Place of Residence" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend">
                                                            <i class="fa fa-user-circle" aria-hidden="true"></i></span>
                                                            <input type="email" name="#" class="form-control"
                                                                placeholder="Occupation" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend">
                                                            <i class="fa fa-user-circle" aria-hidden="true"></i></span>
                                                            <input type="text" name="#" class="form-control"
                                                                placeholder="Employer" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend">
                                                            <i class="fa fa-user-circle" aria-hidden="true"></i></span>
                                                            <input type="text" name="#" class="form-control"
                                                                placeholder="Vote" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <button type="submit" class="btn-first  btn-submit-fill full-width">Book Now
                                                </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoanContact
