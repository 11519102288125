import React from 'react'
import TeamHero from '../components/TeamHero'
import TeamSection from '../components/TeamSection'

const Team = () => {
    return (
        <div>
            <TeamHero />
            <TeamSection />
        </div>
    )
}

export default Team
