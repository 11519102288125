import React from 'react'

const ServicesLayout = () => {
    return (
        <section class="section-padding lw-about-section p-relative">

            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="ln-about-left-side p-relative full-height">
                            <div class="first-img full-height">
                                <img src="assets/images/vector-image-one.jpg" class="image-fit" alt="img" />
                            </div>
                            {/* <div class="second-img">
                                <img src="assets/images/homepage/wallet.jpg" class="image-fit" alt="img" />
                            </div> */}
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="lw-about-section-right">
                            <h3 class="p-relative lw-about-right-heading">
                                We are your financial patners!
                                <br />We offer affordable loans for government salary employees.
                            </h3>
                            <div class="lw-about-right-content">
                                <p class="text-custom-black-raised">We deal with different people from all walks of life.  We have a reputable financial assistance record coupled with great reviews from our clients. The estimated range of our loan products can satisfy every individual that connects to Cynatek.

</p>
                                <div class="lw-about-right-list">
                                    {/* <ul>
                                        <li>
                                            Salary Loan</li>
                                        <li>
                                            Corporate Loan</li>
                                        <li>
                                            Expert Loan</li>
                                        <li>
                                            Business Loan</li>

                                    </ul> */}
                                </div>
                                <div class="lw-about-right-author">


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesLayout
