import React from 'react'

const Slider = () => {
    return (
        <div class="home-area">


            <div class="first-img full-height">
                <img src="assets/images/banner/glass-bg-min.jpg" class="image-fit" alt="img" />
            </div>
            <div class="transform-center">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="banner-slider">
                                <div class="slide-item">
                                    <div class="banner-text">
                                        {/* <h1 class="text-custom-white fw-700">Dedicated To One Client At A Time</h1> */}
                                        <h1 class="text-custom-white">Affordable Loans <h3 class="text-custom-black">For All Government Salary Employees</h3></h1> <a
                                            href="/service" class="btn-first btn-submit-fill">
                                            Explore
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slider
