import React from 'react'
// import AboutLayer1 from '../components/AboutLayer1'
// import AboutLayer2 from '../components/AboutLayer2'
import AboutSkills from '../components/AboutSkills'
import AboutHero from '../Hero/AboutHero';

const About = () => {
    return (
        <div>
            <AboutHero />
            {/* <AboutLayer1 />
            <AboutLayer2 /> */}
            <AboutSkills />
        </div>
    )
}

export default About
