import React from 'react'

const SubLayer = () => {
    return (
        <section class="section-padding LOANLY-advertisement parallax">
            <div class="row">
                <div class="col-12">
                    <div class="advertisement-text">
                        <p class=" text-white fs-20">Affordable Interest Rate - No Application Fees</p>
                        <h3 class="text-white">At Cynatek, we would love to serve you and your expectations
                    
                        </h3>
                        <div class="btn-group">
                            <a href="/service" class="btn-first btn-submit-fill fw-600 mb-xs-20">Salary Loans</a>
                            <a href="/contact" class="btn-first btn-submit-fill fw-600 mb-xs-20">Start Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SubLayer
