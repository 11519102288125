import React from 'react'
import { Link } from 'react-router-dom'

const TeamSection = () => {
	return (
		<section class="section-padding bg-gray">
			<div class="container">
				<div class="row doctors-team-style-2">
					<div class="col-lg-3 col-md-6">
						<div class="team-block p-relative mb-xl-30">
							<div class="inner-box">
								<div class="image animate-img">
									<img src="assets/images/doctors/doctor7.jpg" alt="img" class="full-width" />
									<div class="overlay-box">
										<div class="overlay-inner p-relative full-height">
											<ul class="team-social-box custom">
												<li class="youtube">
													<a href="#" class="fab fa-youtube fs-16"></a>
												</li>
												<li class="linkedin">
													<a href="#" class="fab fa-linkedin fs-16"></a>
												</li>
												<li class="facebook">
													<a href="#" class="fab fa-facebook-f fs-16"></a>
												</li>
												<li class="twitter">
													<a href="#" class="fab fa-twitter fs-16"></a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="lower-content p-relative text-center">
									<h4><a href="team.html" class="text-custom-black fw-600 fs-20">Eachann Jhon</a></h4>
									<p class="designation text-light-white">Educational Loan Advisor</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="team-block p-relative mb-xl-30">
							<div class="inner-box">
								<div class="image animate-img">
									<img src="assets/images/doctors/doctor2.jpg" alt="img" class="full-width" />
									<div class="overlay-box">
										<div class="overlay-inner p-relative full-height">
											<ul class="team-social-box custom">
												<li class="youtube">
													<a href="#" class="fab fa-youtube fs-16"></a>
												</li>
												<li class="linkedin">
													<a href="#" class="fab fa-linkedin fs-16"></a>
												</li>
												<li class="facebook">
													<a href="#" class="fab fa-facebook-f fs-16"></a>
												</li>
												<li class="twitter">
													<a href="#" class="fab fa-twitter fs-16"></a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="lower-content p-relative text-center">
									<h4><a href="team.html" class="text-custom-black fw-600 fs-20"> Addison Smith</a>
									</h4>
									<p class="designation text-light-white">Car Advisor</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="team-block p-relative mb-xl-30">
							<div class="inner-box">
								<div class="image animate-img">
									<img src="assets/images/doctors/doctor3.jpg" alt="img" class="full-width" />
									<div class="overlay-box">
										<div class="overlay-inner p-relative full-height">
											<ul class="team-social-box custom">
												<li class="youtube">
													<a href="#" class="fab fa-youtube fs-16"></a>
												</li>
												<li class="linkedin">
													<a href="#" class="fab fa-linkedin fs-16"></a>
												</li>
												<li class="facebook">
													<a href="#" class="fab fa-facebook-f fs-16"></a>
												</li>
												<li class="twitter">
													<a href="#" class="fab fa-twitter fs-16"></a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="lower-content p-relative text-center">
									<h4><a href="team.html" class="text-custom-black fw-600 fs-20"> Sarah Taylor</a>
									</h4>
									<p class="designation text-light-white">Home Loan Advisor</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="team-block p-relative mb-xl-30">
							<div class="inner-box">
								<div class="image animate-img">
									<img src="assets/images/doctors/doctor4.jpg" alt="img" class="full-width" />
									<div class="overlay-box">
										<div class="overlay-inner p-relative full-height">
											<ul class="team-social-box custom">
												<li class="youtube">
													<a href="#" class="fab fa-youtube fs-16"></a>
												</li>
												<li class="linkedin">
													<a href="#" class="fab fa-linkedin fs-16"></a>
												</li>
												<li class="facebook">
													<a href="#" class="fab fa-facebook-f fs-16"></a>
												</li>
												<li class="twitter">
													<a href="#" class="fab fa-twitter fs-16"></a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="lower-content p-relative text-center">
									<h4><a href="team.html" class="text-custom-black fw-600 fs-20"> Aiken Ward</a></h4>
									<p class="designation text-light-white">Home Loan Advisor</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row doctors-team darkblue">
					<div class="col-lg-3 col-md-6">
						<div class="doctor-item mb-xl-30">
							<div class="doctor-img mb-xl-20">
								<img src="assets/images/doctors/doctor9.jpg" class="rounded-circle full-width"
									alt="image" /> <a href="team.html" class="detail-btn"><i class="fas fa-plus"></i></a>
							</div>
							<div class="doctor-content">
								<h6><a href="team.html" class="text-custom-black fw-700"> Addison Smith</a></h6>
								<p class="designation text-light-white">Home Loan Advisor</p>
								<ul class="custom">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-twitter"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-pinterest-p"></i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="doctor-item mb-xl-30">
							<div class="doctor-img mb-xl-20">
								<img src="assets/images/doctors/doctor10.jpg" class="rounded-circle full-width"
									alt="image" /> <a href="team.html" class="detail-btn"><i class="fas fa-plus"></i></a>
							</div>
							<div class="doctor-content">
								<h6><a href="team.html" class="text-custom-black fw-700"> Sarah Taylor</a></h6>
								<p class="designation text-light-white">Corporate Loan Advisor</p>
								<ul class="custom">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-twitter"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-pinterest-p"></i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="doctor-item mb-xl-30">
							<div class="doctor-img mb-xl-20">
								<img src="assets/images/doctors/doctor8.jpg" class="rounded-circle full-width"
									alt="image" /> <a href="team.html" class="detail-btn"><i class="fas fa-plus"></i></a>
							</div>
							<div class="doctor-content">
								<h6><a href="team.html" class="text-custom-black fw-700"> Aiken Ward</a></h6>
								<p class="designation text-light-white">Home Loan Advisor</p>
								<ul class="custom">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-twitter"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-pinterest-p"></i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="doctor-item mb-xl-30">
							<div class="doctor-img mb-xl-20">
								<img src="assets/images/doctors/doctor7.jpg" class="rounded-circle full-width"
									alt="image" /> <a href="team.html" class="detail-btn"><i class="fas fa-plus"></i></a>
							</div>
							<div class="doctor-content">
								<h6><a href="team.html" class="text-custom-black fw-700">Babatunde Jon</a></h6>
								<p class="designation text-light-white">Real State Advisor</p>
								<ul class="custom">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-twitter"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-pinterest-p"></i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row doctors-team style-2">
					<div class="col-lg-3 col-md-6">
						<div class="doctor-item mb-md-30">
							<div class="doctor-img mb-xl-20">
								<img src="assets/images/doctors/doctor5.jpg" class="full-width" alt="image" />
							</div>
							<div class="doctor-content">
								<h6><a href="team.html" class="text-custom-black fw-700"> Addison Smith</a></h6>
								<p class="designation text-light-white">Home Loan Advisor</p>
								<ul class="custom">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-twitter"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-pinterest-p"></i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="doctor-item mb-md-30">
							<div class="doctor-img mb-xl-20">
								<img src="assets/images/doctors/doctor6.jpg" class="full-width" alt="image" />
							</div>
							<div class="doctor-content">
								<h6><a href="team.html" class="text-custom-black fw-700"> Sarah Taylor</a></h6>
								<p class="text-light-white">Educational Loan Advisor</p>
								<ul class="custom">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-twitter"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-pinterest-p"></i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="doctor-item mb-sm-30">
							<div class="doctor-img mb-xl-20">
								<img src="assets/images/doctors/doctor7.jpg" class="full-width" alt="image" />
							</div>
							<div class="doctor-content">
								<h6><a href="team.html" class="text-custom-black fw-700"> Aiken Ward</a></h6>
								<p class="text-light-white">Business Loan Advisor</p>
								<ul class="custom">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-twitter"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-pinterest-p"></i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-md-6">
						<div class="doctor-item">
							<div class="doctor-img mb-xl-20">
								<img src="assets/images/doctors/doctor2.jpg" class="full-width" alt="image" />
							</div>
							<div class="doctor-content">
								<h6><a href="team.html" class="text-custom-black fw-700">Babatunde Jon</a></h6>
								<p class="text-light-white">PERSONAL LOANyer</p>
								<ul class="custom">
									<li><a href="#"><i class="fab fa-facebook-f"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-twitter"></i></a>
									</li>
									<li><a href="#"><i class="fab fa-pinterest-p"></i></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default TeamSection
