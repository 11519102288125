import React from 'react'

const ContactForm = () => {
    return (
        <section class="section-padding bg-gray contact-us">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1">
                        <div class="contact-form full-height align-self-center bx-wrapper bg-custom-white">
                            <h4 class="text-custom-black fw-600">Get In Touch</h4>
                            <p class="text-light-white no-margin">Please fill in the form below.</p>
                            <form class="form-layout-1">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="text-left">First Name</label>
                                            <input type="text" name="#" class="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="text-left">Last Name</label>
                                            <input type="text" name="#" class="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="text-left">Phone Number</label>
                                            <input type="text" name="#" class="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="text-left">Residence</label>
                                            <input type="text" name="#" class="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="text-left">Occupation</label>
                                            <input type="text" name="#" class="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="text-left">Employer</label>
                                            <input type="text" name="#" class="form-control" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label class="text-left">Email Address</label>
                                            <input type="email" name="#" class="form-control"
                                                placeholder="" />
                                        </div>
                                        <div class="form-group">
                                            <label class="text-left">Message</label>
                                            <textarea rows="5" name="#" class="form-control"
                                                placeholder="Write Something"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit"
                                            class="btn-first btn-submit text-light-blue full-width">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div class="col-xl-7 col-lg-8">
                        <iframe class="full-width full-height"
                            src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default ContactForm
