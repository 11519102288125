import React from 'react'

export const AppSection = () => {
    return (
        <section class="download-app parallax">

            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="section-header section-padding-top">
                            <div class="section-heading">
                                <h3 class="text-custom-white fw-700">Our Mission </h3>
                                <div class="section-description">
                                    <p class="text-custom-white-raised">

                                        Our mission is to build a strong base for poverty alleviation through affordable and self-sustainable financial services in the market by providing tailor Made products to our target market.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="section-header section-padding-top">
                            <div class="section-heading">
                                <h3 class="text-custom-white fw-700">Our Vision</h3>
                                <div class="section-description">
                                    <p class="text-custom-white-raised">
                                        To be a model for the best financial service products practices and microfinance excellence in financial services by:
                                        <ul>
                                            <li>Securing the best position in the highly competitive financial service sector.</li>
                                            <li>aintain a steady and healthy growth through the tier grading system of financial institutions.</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* <div class="app-img">

                            <img src="assets/images/small-logo-min.jpg" class="img-fluid" alt="app" />


                            <img src="assets/images/small-logo-min.jpg" class="img-fluid" alt="app" />

                        </div> */}
                        {/* <div class="app-img-btm ">
                            <img src="assets/images/mobile.png" class="img-fluid" alt="img" />
                        </div> */}
                    </div>
                    <div class="col-lg-6">
                        <div class="app-img-side">
                            <div class="section-header section-padding-top">
                                <div class="section-heading">
                                    <h3 class="text-custom-white fw-700">Our Core Values</h3>
                                    <div class="section-description">

                                    </div>
                                </div>
                            </div>
                            <div class="app-features section-padding-bottom">
                                <ul class="custom">
                                    <li> <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                        <h5 class="text-light-blue">Professionalism </h5>
                                        <p class="text-custom-white">As the saying goes, "Professionalism is not the job you do, it's how you do the job."  At Cynatek, we intend to serve our clients in the best interest of proving our culture to them.</p>
                                    </li>
                                    <li> <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                        <h5 class="text-light-blue">Service excellency and speed</h5>
                                        <p class="text-custom-white">Our team has designed best measures that are secure and monitored to enable service excellent and great speed.</p>
                                    </li>
                                    <li> <i class="fa fa-life-ring" aria-hidden="true"></i>
                                        <h5 class="text-light-blue">Integrity</h5>
                                        <p class="text-custom-white">At Cynatek, we would love to serve you and your expectations. We shall say and do what we believe is right for the good of clients.</p>
                                    </li>
                                    <li> <i class="fa fa-check-circle" aria-hidden="true"></i>
                                        <h5 class="text-light-blue">Reliability</h5>
                                        <p class="text-custom-white">You will always love to work with us because we are reliable.  We are fully persuaded that our services have a great impact on the development of our clients and we can take our relationship with for granted.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
