import React from 'react'

const ServicesWrapper = () => {
    return (
        <section class="genmed-intro">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-3  col-sm-6">
                        <div class="intro-box bg-dark-brown mb-md-20 wow fadeInUp" data-wow-duration="1s"
                            data-wow-delay="0.3s">
                            <div class="main-services-box p-relative mb-xl-30">
                                <div class="main-service-wrapper padding-20">
                                    <div class="icon-box"> <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    </div>
                                    <h5 class="fw-700"><a href="#"
                                        class="text-custom-black">NO GUARANTEE</a></h5>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3  col-sm-6">
                        <div class="intro-box bg-dark-brown mb-md-20 wow fadeInUp" data-wow-duration="1.5s"
                            data-wow-delay="0.6s">
                            <div class="main-services-box p-relative mb-xl-30">
                                <div class="main-service-wrapper padding-20">
                                    <div class="icon-box"> <i class="fa fa-lock" aria-hidden="true"></i>
                                    </div>
                                    <h5 class="fw-700"><a href="#"
                                        class="text-custom-black">NO SECURITY</a></h5>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3  col-sm-6">
                        <div class="intro-box bg-dark-brown mb-xs-20 wow fadeInUp" data-wow-duration="2s"
                            data-wow-delay="1s">
                            <div class="main-services-box p-relative mb-xl-30">
                                <div class="main-service-wrapper padding-20">
                                    <div class="icon-box"> <i class="fa fa-flag" aria-hidden="true"></i>
                                    </div>
                                    <h5 class="fw-700"><a href="#"
                                        class="text-custom-black">NO APPLICATION FEES</a></h5>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="intro-box bg-dark-brown wow fadeInUp" data-wow-duration="2.5s"
                            data-wow-delay="1.3s">
                            <div class="main-services-box p-relative mb-xl-30">
                                <div class="main-service-wrapper padding-20">
                                    <div class="icon-box"> <i class="fa fa-user" aria-hidden="true"></i>
                                    </div>
                                    <h5 class="fw-700"><a href="#"
                                        class="text-custom-black">AFFORDABLE INTEREST RATE</a></h5>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesWrapper
