import React from 'react'

const SalaryLoan = () => {
    return (
        <section class="main-services section-padding p-relative">

            <div class="container">
                <div class="section-header">
                    <div class="section-heading">
                        <h3 class="text-dark-blue fw-700">Salary Loans</h3>
                        <div class="section-description">
                            <p class="text-custom-black-raised">We offer affordable salary loans to all government employees and the repayment period is up to 72 months.</p>
                        </div>
                    </div>
                    {/* <div class="section-btn"> <a href="service.html" class="btn-first btn-submit text-custom-black">View
                            More</a>
                    </div> */}
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="main-services-box p-relative mb-xl-30">
                            <div class="main-service-wrapper padding-20">
                                {/* <div class="icon-box"> <i class="fa fa-home" aria-hidden="true"></i>
                                </div> */}
                                <h5 class="fw-700"><a href="#" class="text-dark-blue">Product Features</a>
                                </h5>

                                <ul class="text-custom-black-raised no-margin">
                                    <li>Affordable Interest rates</li>
                                    <li>No security</li>
                                    <li>No application Fees</li>
                                    <li>No Guarantee</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="main-services-box p-relative mb-xl-30">
                            <div class="main-service-wrapper padding-20">
                                {/* <div class="icon-box"> <i class="fa fa-home" aria-hidden="true"></i>
                                </div> */}
                                <h5 class="fw-700"><a href="#" class="text-dark-blue">Loan Term (months)</a>
                                </h5>

                                <ul class="text-custom-black-raised no-margin">
                                    <li>3 -  72</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="main-services-box p-relative mb-xl-30">
                            <div class="main-service-wrapper padding-20">
                                {/* <div class="icon-box"> <i class="fa fa-home" aria-hidden="true"></i>
                                </div> */}
                                <h5 class="fw-700"><a href="service-detail.html" class="text-dark-blue ">Requirements</a>
                                </h5>
                                <ul class="text-custom-black-raised no-margin">
                                    <li>National Identity Card</li>
                                    <li>Worker’s Identity Card</li>
                                    <li>1 Passport Photograph</li>
                                    <li>Appointment / Confirmation Letter</li>
                                    <li>Introduction Letter</li>
                                    <li>Bank Statement</li>
                                    <li>Pay slip</li>
                                </ul>
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </section>
    )
}

export default SalaryLoan
