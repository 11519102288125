import React from 'react'
import ContactFooter from '../components/ContactFooter'
import ContactForm from '../components/ContactForm'
import ContactHero from '../Hero/ContactHero'

const Contact = () => {
    return (
        <div>
            <ContactHero />
            <ContactForm />
            <ContactFooter />
        </div>
    )
}

export default Contact
