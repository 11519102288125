import React from 'react'
import { AppSection } from '../components/AppSection'
import ServicesLayout from '../components/ServicesLayout'
import ServicesWrapper from '../components/ServicesWrapper'

import LoanContact from '../components/LoanContact'
import Slider from '../components/Slider'
import SubLayer from '../components/SubLayer'
import FaqLayer from "../components/FaqLayer";

const Home = () => {
    return (
        <div>
            <Slider />
            <ServicesWrapper />
            <ServicesLayout />
            <AppSection />

            <SubLayer />
            <FaqLayer />
            <LoanContact />


        </div>
    )
}

export default Home
