import React from 'react'

const AboutSkills = () => {
    return (
        <section class="section-padding bg-gray about-story ">
            <div class="container">
                <div class="section-header">
                    <div class="section-heading">
                        <h3 class="text-dark-blue fw-700">Cynatek Holdings LTD</h3>
                        <div class="section-description">
                            <p class="text-custom-black-raised">We are a credit finance company offering salary loans to government employees.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="tabs square-tabs">

                            <div class="tab-content padding-20 bg-custom-white bx-wrapper">
                                <div class="tab-pane fade show active" id="start">
                                    <div class="tab-inner">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="img-sec mb-md-40">
                                                    <img src="assets/images/kampala-image.png" class="full-width" alt="img" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6 align-self-center">
                                                <div class="content-box">
                                                    <h4 class="text-custom-black fw-600"> About Cynatek Holdings LTD
                                                    </h4>
                                                    <p class="text-custom-black-raised">Lorem Ipsum is simply dummy text of the
                                                        printing and typesetting industry.</p>
                                                    <p class="text-custom-black-raised">Lorem Ipsum is simply dummy text of the
                                                    printing and typesetting industry. Lorem Ipsum has been the
                                                    industry's standard dummy text. Lorem Ipsum has been the
                                                        industry's standard dummy text.</p>
                                                    <p class="text-custom-black-raised">Lorem Ipsum is simply dummy text of the
                                                    printing and typesetting industry. Lorem Ipsum has been the
                                                        industry's standard dummy text.</p>

                                                </div>
                                            </div>
                                        </div>
                                        <br /> <br />
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="content-box">
                                                    <h4 class="text-dark-blue fw-600"> Our Vision
                                                    </h4>
                                                    <p class="text-custom-black-raised">To be a model for the best financial service products practices and microfinance excellence in financial services by:
                                                    <ul class="text-custom-black-raised">
                                                            <li>Securing the best position in the highly competitive financial service sector.</li>
                                                            <li>aintain a steady and healthy growth through the tier grading system of financial institutions.</li>
                                                        </ul>
                                                    </p>

                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="content-box">
                                                    <h4 class="text-dark-blue fw-600"> Our Mission
                                                    </h4>
                                                    <p class="text-custom-black-raised"> Our mission is to build a strong base for poverty alleviation through affordable and self-sustainable financial services in the market by providing tailor Made products to our target market.</p>



                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSkills
