import React from 'react'
// import { Link } from 'react-router-dom'

const Navigation = () => {
    return (

        <div class="main-navigation-style-1">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="navigation">
                            <div class="logo">

                                <img src="assets/images/cynatek-logo.png" class="img-fluid image-fit" alt="Logo" width="152" height="52" />

                            </div>
                            <div class="main-menu">
                                <div class="mobile-logo">

                                    <img src="assets/images/cynatek-logo.png" class="img-fluid image-fit" alt="Logo" />

                                </div>
                                <nav>
                                    <ul class="custom">

                                        <li class="menu-item"> <a href="/" class="text-light-grey">Home
                                                </a>
                                        </li>




                                        {/* <li class="menu-item"> <a href="blog.html" class="text-light-grey">Blog</a>
                                        </li> */}





                                        {/* <li class="menu-item"> <a href="team.html" class="text-light-grey">Our
                                                    Team</a>
                                        </li> */}

                                        <li class="menu-item"> <a href="/about" class="text-light-grey">About
                                                    Us</a>
                                        </li>

                                        <li class="menu-item"> <a href="/service"
                                            class="text-light-grey">Salary Loans</a>
                                        </li>
                                        <li class="menu-item"> <a href="/team"
                                            class="text-light-grey">Team</a>
                                        </li>
                                        <li class="menu-item  "> <a href="/contact"
                                            class="text-light-grey">Contact Us</a>
                                        </li>

                                    </ul>
                                </nav>
                                <div class="right-side">
                                    <div class="btn-first"> <a href="/loan" class="btn-first btn-submit-fill">
                                        APPLY FOR A LAON
                                            </a>
                                    </div>
                                </div>
                            </div>
                            <div class="hamburger-menu">
                                <div class="menu-btn"> <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Navigation
