import React from 'react'

const FaqLayer = () => {
    return (
        <section class="section-padding loan-faqs-sec bg-light-white findrate-top">
            <div class="container">
                <div class="row">
                    <div class="col-xl-5 col-lg-6 align-self-center">
                        <div class="section-header">
                            <div class="section-heading">
                                <h3 class="text-dark-blue fw-700">FAQs</h3>
                                <div class="section-description">
                                    <p class="text-custom-black-raised no-margin">Got questions?
                                        <br />Our team is here for you
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="loan-faqs-leftside mb-md-80">
                            <div class="support-card mb-xl-20">
                                <div class="schdule">
                                    <p class="text-custom-black-raised">Email us: info@cynatekholdinglimited.com</p>
                                    <p class="text-custom-black-raised">Call Us: +256 773 956212</p>



                                    <p class="text-dark-blue-raised no-margin">Working Hours</p>
                                    <p class="text-custom-black no-margin">Mon - Fri : 8am -6pm</p>
                                    <p class="text-custom-black no-margin">Sat : 8am - 3pm</p>

                                </div>
                            </div> <a href="/loan" class="btn-first btn-submit-fill"> Apply for Loan</a>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-6 align-self-center">
                        <div id="accordion" class="custom-accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <button class="collapsebtn" data-toggle="collapse" data-target="#collapseOne">Who is eligble to apply? </button>
                                </div>
                                <div id="collapseOne" class="collapse show" data-parent="#accordion">
                                    <div class="card-body">
                                        <p class="text-custom-black-raised">Must be a government employee with an IPS number.</p>

                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <button class="collapsebtn collapsed" data-toggle="collapse"
                                        data-target="#collapseTwo">What are the requirements to apply?</button>
                                </div>
                                <div id="collapseTwo" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <p class="text-custom-black-raised no-margin">
                                            <ul class="text-custom-black-raised no-margin">
                                                <li>National Identity Card</li>
                                                <li>Worker’s Identity Card</li>
                                                <li>1 Passport Photograph</li>
                                                <li>Appointment / Confirmation Letter</li>
                                                <li>Introduction Letter</li>
                                                <li>Bank Statement</li>
                                                <li>Pay slip</li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <button class="collapsebtn collapsed" data-toggle="collapse"
                                        data-target="#collapseThree">Are there any fees?</button>
                                </div>
                                <div id="collapseThree" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <p class="text-custom-black-raised no-margin">
                                            <ul class="text-custom-black-raised no-margin">
                                                <li>No Application Fees</li>
                                                <li>No security</li>
                                                <li>No Guarantee</li>

                                            </ul>
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <button class="collapsebtn collapsed" data-toggle="collapse"
                                        data-target="#collapseFour">How do I qualify for debt consolidation loan?</button>
                                </div>
                                <div id="collapseFour" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <p class="text-custom-black-raised">Getting a debt consolidation loan depends on a number of factors such as repayment history, income to debt ratio, credit score etc. If you meet all the eligibility criteria, you can easily get a debt consolidation loan.</p>

                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFive">
                                    <button class="collapsebtn collapsed" data-toggle="collapse"
                                        data-target="#collapseFive">What is the lowest salary loan amount?</button>
                                </div>
                                <div id="collapseFive" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <p class="text-custom-black-raised">At present date, Cynatek provides the lowest home loan amount which is UGX 50,000.</p>

                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingsix">
                                    <button class="collapsebtn collapsed" data-toggle="collapse"
                                        data-target="#collapsesix">How long will it take to get the money?</button>
                                </div>
                                <div id="collapsesix" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <p class="text-custom-black-raised no-margin">Depending on the loan amount and application time frame, there may be a waiting period before you receive funds. Some loans process within 48 hours, but others may take a bit longer. Take these time periods into account when deciding which loan product to apply for.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingseven">
                                    <button class="collapsebtn collapsed" data-toggle="collapse"
                                        data-target="#collapseseven">How long can i take to pay for the loan?</button>
                                </div>
                                <div id="collapseseven" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <p class="text-custom-black-raised">Our loan term begins from 3 - 72 months. </p>

                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingeight">
                                    <button class="collapsebtn collapsed" data-toggle="collapse"
                                        data-target="#collapseeight">How can I use a salary loan?</button>
                                </div>
                                <div id="collapseeight" class="collapse" data-parent="#accordion">
                                    <div class="card-body">
                                        <p class="text-custom-black-raised no-margin">You’re typically not limited in how you
                                        can use your salary loan. Salary loans can be used to meet unexpected needs like medical bills,
                                        take care of a major purchase like a new refrigerator, or fund home
                                        improvement projects.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section >
    )
}

export default FaqLayer
