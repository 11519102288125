import React from 'react'

const ContactFooter = () => {
    return (
        <section class="section-padding bg-gray contact-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="contact-info bx-wrapper bg-custom-white text-center mb-md-40">
                            <div class="contact-info-wrapper">
                                <div class="icon mb-xl-20"> <i class="fa fa-phone" aria-hidden="true"></i>
                                </div>
                                <h5 class="text-custom-black fw-600">Call</h5>
                                <p class="text-custom-black-raised">+(256)773 956212</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="contact-info bx-wrapper bg-custom-white text-center mb-md-40">
                            <div class="contact-info-wrapper">
                                <div class="icon mb-xl-20"> <i class="fa fa-envelope" aria-hidden="true"></i>
                                </div>
                                <h5 class="text-custom-black fw-600">Email</h5>
                                <p
                                    class="text-custom-black-raised">info@cynatekholdinglimited.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <div class="contact-info bx-wrapper bg-custom-white text-center">
                            <div class="contact-info-wrapper">
                                <div class="icon mb-xl-20"> <i class="fa fa-map-marker" aria-hidden="true"></i>
                                </div>
                                <h5 class="text-custom-black fw-600">Address</h5>
                                <p
                                    class="text-custom-black-raised">Plot 4 Crown House Kampala Road, Floor 2 Room 3</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactFooter
