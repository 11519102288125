import React from 'react'

const AboutHero = () => {
    return (
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">About Us</h1>
                                <ul class="custom">
                                    <li> <a href="index.html" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHero