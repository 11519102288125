import React from 'react'
import LoanContact from '../components/LoanContact'

import SalaryLoan from '../components/SalaryLoan'

import ServiceHero from '../Hero/ServiceHero'

const Services = () => {
    return (
        <div>
            <ServiceHero />
            <SalaryLoan />
            <LoanContact />
        </div>
    )
}

export default Services
