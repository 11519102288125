import React from 'react'

const CopyRight = () => {
    return (
        <div class="copyright">
            <div class="container">
                <div class="row">

                    <div class="col-lg-12">
                        <p class="text-custom-white no-margin">© Cynatek Holdings LTD - 2022 | All Right Reserved. <a href="https://www.xyola.com"
                            target="_blank" class="text-custom-white">Xyola Services</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CopyRight
