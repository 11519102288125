import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import CopyRight from "./components/CopyRight";
// import FaqLayer from "./components/FaqLayer";
import Footer from "./components/Footer";

import Navigation from "./components/Navigation";



// import BackToTop from "./components/BackToTop";
// import TeamSection from "./components/TeamSection";
// import TopBar from "./components/TopBar";
import Home from './pages/Home';
import Team from './pages/Team';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';
import LoanApp from './pages/LoanApp';




function App() {
  return (
    <Router>
      <div class="main-body">
        <header class="header-style-1">
          {/* <TopBar /> */}
          <Navigation />

        </header>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/team" component={Team} />
          <Route path="/about" component={About} />
          <Route path="/service" component={Services} />
          <Route path="/contact" component={Contact} />
          <Route path="/loan" component={LoanApp} />

        </Switch>
        {/* <FaqLayer /> */}
        <Footer />
        <CopyRight />
        {/* <BackToTop /> */}

      </div>
    </Router>
  );
}

export default App;
