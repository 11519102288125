import React from 'react'
import LoanContact from '../components/LoanContact'

const LoanApp = () => {
    return (
        <div>
            <LoanContact />
        </div>
    )
}

export default LoanApp
